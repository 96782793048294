.editor {
  background-color: rgb(13, 17, 22);
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  height: 950px; /* Set a fixed height for the editor */
  overflow-y: auto; /* Make the editor scrollable */
  color: #d4d4d4; /* Light text color */
  font-family: 'Consolas', monospace; /* Monospace font */
  line-height: 1.5;
}

.editor section.home-intro h1 {
  font-size: 2.2rem;
  font-weight: normal;
  text-align: left;
  color: #75beff;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.cursor {
  background-color: #75beff; /* Light blue color for cursor */
  animation: blink 1s infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.editor hr {
  border: 1px solid #444; /* Dark gray color for horizontal rule */
  margin: 20px 0;
}

.editor h2 {
  color: #75beff; /* Light blue color for headings */
  margin-bottom: 10px;
}

.editor p {
  margin-bottom: 20px;
}

.container a {
  color: rgb(172, 144, 199);
}

