.console {
    background-color: rgb(1, 4, 8);
    flex-grow: 0;
    flex-shrink: 0;
    height: 300px;
    border-top: 1px solid #333842;
  }
  
  .console-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .console-tabs {
    display: flex;
    background-color: rgb(1, 4, 8);
    padding: 5px;
    max-width: calc(100% - 40px);
  }
  
  .tab {
    padding: 5px 10px;
    color: white;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .tab:hover {
    background-color: #333842;
  }
  
  .tab.active {
    border-bottom: 2px solid rgb(232, 135, 109);
  }
  
  .console-actions {
    display: flex;
    padding: 5px;
  }
  
  .console-action {
    padding: 0 8px;
    cursor: pointer;
    color: white;
    font-size: 14px;
  }
  
  .console-action:hover {
    background-color: #333842;
  }
  
  .console-content {
    flex: 1;
    padding: 10px;
    color: white;
    font-size: 14px;
    position: relative;
  }
  
  .scrollable-container {
    max-height: calc(100% - 80px); /* Adjust the height based on the content and header space */
    overflow-y: auto;
  }
  
  
  
  .placeholder-text {
    color: white;
    padding: 10px;
  }
  
  .console-content-wrapper {
    height: calc(100% - 40px);
    overflow-y: auto;
  }
  