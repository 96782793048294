/* CVStyles.css */
.cv-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  
  font-family: Arial, sans-serif;
  line-height: 1.5;
  text-align: left; /* Ensure content is left-aligned */
}

.cv-header {
  margin-bottom: 20px;
}

.cv-header h1,
.cv-header p {
  text-align: center; /* Center the header text */
  color: #9cdcfe;
}

.cv-section {
  margin-bottom: 20px;
}

.cv-section-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  text-align: center; /* Center the section titles */
  color: #6bdd67;
}
  .cv-section-content {
    margin-left: 20px;
  }
  
  .cv-job-title {
    font-weight: bold;
    margin-top: 10px;
    color: #fa0000; 
  }
  
  .cv-skills-list,
  .cv-certification-list {
    list-style-type: square;
    list-style-position: outside;
    padding-left: 20px;
  }
  
  .cv-list-item {
    margin-bottom: 5px;
    text-align: left;
  }
  
  /* CVStyles.css */

/* ... */

.cv-projects-list {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0; /* Remove left margin from the projects list */
  }
  
  .cv-projects-list > li {
    padding-left: 0; /* Remove left padding from each list item */
    margin-left: 0; /* Remove left margin from each list item */
  }
  
  .cv-projects-container {
    padding-left: 0;
  }
  
  .cv-project {
    margin-left: 0;
  }
  
  .cv-project-title {
    font-weight: bold;
    margin-top: 10px;
    color: #c9754e; 
  }
  
  .cv-project-details {
    list-style-type: square;
    list-style-position: outside;
    padding-left: 20px;
  }
  /* ... */
  
  