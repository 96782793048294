.explorer {
  position: relative;
  top: 0;
  bottom: 0;
  left: 60px;
  width: var(--explorer-width);
  background-color: rgb(1, 4, 8);
  border-right: 1px solid #333842;
  transition: width 0.3s;
}

.logo {
  width: 50px;
  height: auto;
  margin-bottom: 30px;
}

.explorer-content {
  padding: 10px;
  color: #ffffff;
  font-size: 14px;
  overflow-y: auto;
}

.explorer-item {
  display: flex;
  align-items: center;
  padding: 2px 10px;
  margin-bottom: 2px;
  cursor: pointer;
  user-select: none;
}

.explorer-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  fill: #ffffff;
}

.selected {
  background-color: rgba(255, 255, 255, 0.1);
}

.explorer-title {
  padding: 8px;
  font-size: 12px;
  
}
