.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(1, 4, 8);
    padding: 15px 10px;
    border-bottom: 1px solid #333842;
    font-size: 12px;
    color: rgb(248, 248, 248);
    height: 25px;
  }
  
  .navbar-tabs {
    display: flex;
    border-right: 1px solid #333842;
    padding: 15px 10px;
  }
  
  .tab-container {
    display: flex;
    align-items: center;
    background-color: rgb(1, 4, 8);
    padding: 3px 6px;
    border-radius: 3px;
    margin-right: 5px;
  }
  
  .tab-container:hover {
    background-color: #3b3b3b;
    color: #fff;
}

.tab-name {
font-size: 14px;
}

.close-tab {
margin-left: 5px;
font-size: 14px;
cursor: pointer;
}

.close-tab:hover {
color: rgb(232, 135, 109);
}

.navbar-actions {
display: flex;
}

.run-icon,
.stop-icon,
.more-options {
margin-left: 5px;
font-size: 14px;
cursor: pointer;
}

.run-icon:hover,
.stop-icon:hover,
.more-options:hover {
color: rgb(232, 135, 109);
}  