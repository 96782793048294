.sidebar {
    width: 60px;
    background-color: rgb(13, 17, 22);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0;
    position: fixed;
    height: 100%;
    border-right: 1px solid #333842;
  }
  
  nav ul {
    list-style-type: none;
    padding: 0;
  }
  
  nav li {
    margin-bottom: 20px;
    padding: 0;
  }
  
  nav a {
    color: rgb(189, 189, 189);
    text-decoration: none;
    display: block;
    padding: 0 15px;
    font-size: 24px;
    transition: background-color 0.3s;
    border-left: 3px solid transparent;
    margin-left: 0;
  }
  
  nav a.active {
    color: rgb(255, 255, 255);
    position: relative;
  }
  
  nav a.active::before {
    content: '';
    position: absolute;
    left: -6px;
    width: 6px;
    height: 100%;
    background-color: rgb(232, 135, 109);
  }
  
  nav a:hover {
    color: rgb(255, 255, 255);
  }
  
  .placeholder {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-bottom: 20px;
  }
  