

.why-vscode-container {

    color: #d4d4d4; /* Light text color */
    padding: 20px;
    font-family: 'Consolas', monospace; /* Monospace font */
    line-height: 1.5;
  }
  
  .why-vscode-container h1,
  .why-vscode-container h2 {
    color: #75beff; /* Light blue color for headings */
    margin-bottom: 10px;
  }
  
  .why-vscode-container p {
    margin-bottom: 20px;
  }
  
  .why-vscode-container .cursor {
    background-color: #75beff; /* Light blue color for cursor */
    animation: blink 1s infinite;
  }
  
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
  
  .why-vscode-container hr {
    border: 1px solid #444; /* Dark gray color for horizontal rule */
    margin: 20px 0;
  }
  