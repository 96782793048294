.vscode-footer {
    display: flex;
    justify-content: space-between;
    background-color: rgb(13, 17, 22);
    padding: 5px 10px;
    border-top: 1px solid #333842;
    font-size: 12px;
    color: white;
    flex-grow: 0;
    flex-shrink: 0;
    height: 25px;
  
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  
    border-bottom: 1px solid #333842;
  }
  
  .footer-left {
    display: flex;
  }
  
  .footer-right {
    display: flex;
  }
  
  .footer-section {
    margin-left: 10px;
  }
  
  .green {
    color: #59cf6d;
  }
  
  .bold {
    font-weight: bold;
  }
  