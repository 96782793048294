.main {
    width: calc(100% - 60px);
    margin-left: 60px;
    display: flex;
    flex-direction: column;
    flex: 1;
    transition: margin-left 0.3s;
    height: 100vh;
  }
  
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .main-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: fixed;
  }

  
  .editor {
    flex: 1;
    overflow: auto;    
  }
  
  /* Added this to avoid grey margin area */
  .editor::after { 
    content: '';
    display: block;
    height: 20px;
  }
  
  